import { subscriptionUiSteps, type SubscriptionBreadcrumb } from '@orus.eu/dimensions'
import { TechnicalError } from '@orus.eu/error'
import type { CompoundIconName } from '@orus.eu/pharaoh'

export type BreadcrumbsTree = { readonly [key in SubscriptionBreadcrumb]?: readonly SubscriptionBreadcrumb[] }

let breadcrumbsRootsChildren: BreadcrumbsTree
let breadcrumbsRoots: SubscriptionBreadcrumb[] | undefined = undefined

export function getIconForNumber(value: number): CompoundIconName {
  switch (value) {
    case 1:
      return 'circle-1-regular'
    case 2:
      return 'circle-2-regular'
    case 3:
      return 'circle-3-regular'
    case 4:
      return 'circle-4-regular'
  }
  throw new TechnicalError('No icon configured for this number', { context: { value } })
}

export function getBreadcrumbsRoots(): readonly SubscriptionBreadcrumb[] {
  if (!breadcrumbsRoots) {
    breadcrumbsRoots = [...new Set(subscriptionUiSteps.map((step) => step.breadcrumbs[0]))]
  }

  return breadcrumbsRoots
}

export function getBreadcrumbsRootsChildren(): BreadcrumbsTree {
  if (!breadcrumbsRootsChildren) {
    breadcrumbsRootsChildren = subscriptionUiSteps.reduce<BreadcrumbsTree>(
      (children, { breadcrumbs }) =>
        breadcrumbs.length === 1
          ? children
          : {
              ...children,
              [breadcrumbs[0]]: [...new Set([...(children[breadcrumbs[0]] || []), breadcrumbs[1]])],
            },
      {},
    )
  }

  return breadcrumbsRootsChildren
}
