import { subscriptionUiStepsIndicesById, type SubscriptionStepId } from '@orus.eu/dimensions'

export type SubscriptionPageCategory = 'checkout' | 'lead_form' | 'subscription_form'

export function getPageCategory(subscriptionStepId: SubscriptionStepId): SubscriptionPageCategory {
  const currentStepIndex = subscriptionUiStepsIndicesById[subscriptionStepId]

  if (currentStepIndex < subscriptionUiStepsIndicesById['quote']) return 'lead_form'
  if (currentStepIndex < subscriptionUiStepsIndicesById['checkout']) return 'subscription_form'
  return 'checkout'
}
