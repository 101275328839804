import { css } from '@emotion/react'
import type { SubscriptionStepValidationIssue } from '@orus.eu/backend/src/services/subscription/subscription-service'
import { PersistentNotification, spacing } from '@orus.eu/pharaoh'
import type { ActionButton } from '@orus.eu/pharaoh/src/components/callout-notification/common/callout-notification-template'
import { memo, useMemo } from 'react'
import { useNavigateTo } from '../../../lib/hooks/use-navigate-to-route'

type SubscriptionValidationIssueBlockProps = {
  issue?: SubscriptionStepValidationIssue | null
  className?: string
}
export const SubscriptionValidationIssueBlock = memo<SubscriptionValidationIssueBlockProps>(
  function SubscriptionValidationIssueBlock({ issue, className }) {
    if (!issue) return <></>
    switch (issue.type) {
      case 'email-already-used':
        return <EmailUsedIssue className={className} subscriptionId={issue.subscriptionId} email={issue.email} />
    }
  },
)

const EmailUsedIssue = memo<{ subscriptionId: string; email: string; className?: string }>(function EmailUsedIssue({
  subscriptionId,
  email,
  className,
}) {
  const naviateToLogin = useNavigateTo({ to: '/login', search: { continueSubscriptionId: subscriptionId, email } })
  const actionButton = useMemo<ActionButton>(
    () => ({
      label: 'Me connecter',
      onClick: naviateToLogin,
    }),
    [naviateToLogin],
  )
  return (
    <PersistentNotification
      variant="warning"
      title="Vous avez déjà un compte"
      className={className}
      actionButton={actionButton}
      css={css`
        margin-bottom: ${spacing[70]};
      `}
    >
      Un compte Orus existe déjà avec cette adresse email. Connectez-vous pour accéder à votre espace et souscrire à un
      nouveau contrat d’assurance.
    </PersistentNotification>
  )
})
